:root {
    --main-bg-color: #085382;
    --main-bg-color-light: #3879a3;
    --main-bg-color-dark: #05426a;
}

.bw-components.lightbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.bw-components.lightbox .lightbox-backdrop {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(8, 25, 50, 0.85);
}

.bw-components.lightbox .lightbox-content {
    display: flex;
    box-sizing: border-box;
    padding: 0;
    z-index: 10;
    background-color: white;
    border-radius: 3px;
    width: calc(100% - 40px);
    max-width: 550px;
    max-height: 85%;
    overflow: hidden;
}

.bw-components.lightbox .lightbox-content .lightbox-scroll-container {
    overflow-y: auto;
    padding: 20px;
    width: 100%;
}

.bw-components.lightbox .lightbox-content.certificates {
    max-width: none;
}
.bw-components.lightbox .lightbox-content .lightbox-scroll-container.lightbox-content-title {
    padding-top: 0;
}

.bw-components.lightbox .lightbox-title {
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
}

.bw-components.lightbox .lightbox-title h4 {
    margin: 0;
}

.bw-components.lightbox .lightbox-title button {
    border: none;
    padding: 0;
    background: none;
    align-self: center;
}
.bw-components.lightbox .lightbox-title svg {
    width: 0.8rem;
    opacity: 0.2;
    cursor: pointer;
}
.bw-components.lightbox .lightbox-title svg:hover {
    opacity: 0.5;
}

.lds-roller-centered-wrapper {
    display: flex;
    justify-content: center;
    margin: 4em;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--main-bg-color);
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.progress-wrapper {
    position: relative;
}

.progress {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.lightbox-content .bw-components table {
    table-layout: auto;
}

.bw-components table {
    border-collapse: collapse;
    width: 100%;
}

.bw-components table td,
.bw-components table th {
    overflow: hidden;
    text-overflow: ellipsis;
}

.bw-components table a {
    text-decoration: none;
}

.bw-components th > span.sortable,
.bw-components th > span.filterable {
    cursor: pointer;
}

.bw-components th > span.sortable:hover,
.bw-components th > span.filterable:hover {
    color: var(--main-bg-color-light);
}

.bw-components th {
    text-align: left;
}

.bw-components tr:nth-child(even) {
    background-color: #eff4ff;
}

.bw-components tbody tr:hover {
    background-color: #dae5fe;
}

.bw-components tr.clickable {
    cursor: pointer;
}

.bw-components td.centered,
.bw-components th.centered {
    text-align: center;
}

.bw-components td.strong {
    font-weight: bold;
}

.bw-components .table-header {
    display: flex;
}

.bw-components table .column-filter {
    position: absolute;
    background: white;
    padding: 0.5em;
}

.bw-components .table-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
    flex: 1;
}

.bw-components span.searchbox input {
    border: none;
    padding-left: 0.4rem;
}

.bw-components span.searchbox input:focus {
    outline: none;
}

.bw-components span.searchbox {
    border: 1px solid #e8e8e8;
    border-radius: 0.25em;
    outline: none;
    margin-left: 1rem;
    padding: 0.4rem;
}

.bw-components span.searchbox:focus-within {
    box-shadow: 0 0 5px #ddd;
}

.bw-components span.searchbox {
    display: inline-flex;
    align-items: center;
}

/* Styles for card mode (presentational styles are marked as optional) */
@media (max-width: 1280px) {
    .bw-components table {
        display: block;
    }

    .bw-components thead {
        display: none;
    }

    .bw-components tbody {
        display: table;
        width: calc(100% - 6px);
    }

    .bw-components tr {
        display: table-row-group;
        border: 3px solid #888888;
    }

    .bw-components td {
        display: table-row;
    }

    .bw-components td:before,
    .bw-components td > span {
        display: table-cell;
        padding: 7px 13px;
        border: 1px solid #e8e8e8;
    }

    .bw-components td:before {
        content: attr(data-label);
        font-weight: 600;
    }

    .bw-components .table-toolbar {
        flex-direction: column;
    }

    .bw-components td.centered,
    .bw-components th.centered {
        text-align: left;
    }

    .bw-components .table-search {
        display: flex;
        flex-direction: column;
    }

    .bw-components .table-toolbar .table-search > * {
        margin: 0;
        text-align: center;
    }
}

/* Styles for normal mode (all are optional, presentational styles) */
@media (min-width: 856px) {
    .bw-components th {
        white-space: nowrap;
        font-weight: 600;
    }

    .bw-components th,
    .bw-components td {
        padding: 7px 13px;
        border: 1px solid #e8e8e8;
    }
}

.main-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.main-section-header h1 {
    margin-right: 1em;
}

div.main-section-header > div {
    display: inline-flex;
    align-items: center;
}

@media (max-width: 1280px) {
    main.nav-page > div > div.main-section-header {
        flex-direction: column;
    }
}

.bw-components.files {
    display: inline-block;
}

.bw-components.files .file {
    display: inline-flex;
    color: black;
    text-decoration: none;
}

.bw-components.files .file {
    margin: 0.25rem 0.5rem;
}

.bw-components.files .file .bw-components.icon {
    margin: 0 0.25rem 0 0;
}

.bw-components.files .file .image .thumbnail.small {
    max-height: 1rem;
    max-width: 1rem;
    margin: 0 3px 0 0;
}

.bw-components.files .file .image .preview {
    display: none;
    background-color: white;
    position: absolute;
}

.bw-components.files .file .image .preview img {
    max-width: 200px;
    max-height: 200px;
}

.bw-components.files .file:hover .image .preview {
    display: block;
}

input,
select,
textarea {
    font-size: 1rem;
    font-family: Arial, sans-serif;
    padding: 0.35rem 0.5rem;
    border: 1px solid black;
    border-radius: 0.25rem;
}

button {
    font-size: initial;
}

.input-field {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
}

.input-field input,
.input-field select,
.input-field div {
    box-sizing: border-box;
    flex: 1;
}

.bw-components.form-input input[type="email"].invalidEmail {
    background-color: pink;
    border-color: red;
}
.bw-components.form-input input[type="email"].validEmail {
    background-color: lightgreen;
}
.bw-components.form-input input[type="email"].invalidEmail:focus-within {
    background-color: pink;
}

.bw-components.form-input .label-help {
    display: flex;
}
.bw-components.form-input .label-help svg {
    padding-left: 0.5rem;
}

.bw-components.submit-and-cancel {
    margin-bottom: 1.5rem;
}

.bw-components.form-input.input label {
    display: flex;
    flex-direction: column;
}

.bw-components.submit-and-cancel button {
    margin-top: 1.5rem;
}

.bw-components.submit-and-cancel button {
    margin-right: 1rem;
}

.bw-components.form-input.file button {
    cursor: pointer;
    margin: 0 0.5rem 0 0;
    border: 0;
    padding: 0;
    background: none;
}

.bw-components.form-input.file input[type="file"].hide {
    display: none;
}

.bw-components.form-input.checkbox .hidden {
    display: none;
}

.bw-components.form-input.checkbox label {
    display: flex;
    align-items: center;
}

.bw-components.form-input.checkbox button {
    background: none;
    padding: 0;
    border: 0;
}

.bw-components.form-input.checkbox .checkbox.disabled {
    color: #dbdbdb;
}

.bw-components.form-input.checkboxgroup .bw-components.form-input.checkbox {
    margin-top: 0.5rem;
}

.btn,
.btn:focus {
    background-color: #e6e6e6;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    border: none;
}

.btn:hover:enabled {
    background-color: #ccc;
}

.btn:disabled {
    background-color: #f5f5f5;
    cursor: default;
}

.btn-primary {
    background-color: var(--main-bg-color);
    color: white;
}

.btn-primary:hover:enabled {
    background-color: var(--main-bg-color-dark);
}

.btn-primary:disabled {
    background-color: #9cbacd;
}

.bw-components.form-input.combo .input-field {
    position: relative;
    height: 2.1rem;
    display: block;
}

.bw-components.form-input.combo .input {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid #000;
    border-radius: 6px;
    background-color: #fff;
}

.bw-components.form-input.combo .input.show {
    z-index: 100;
}

.bw-components.form-input.combo .input .top {
    width: 100%;
    display: flex;
}

.bw-components.form-input.combo .input .top button {
    border: none;
    cursor: pointer;
    padding: 0;
    border-left: 1px solid #000;
    background: none;
    display: flex;
}

.bw-components.form-input.combo .input .top input {
    height: 2rem;
    border: 0;
    border-radius: 0;
    outline: none;
}

.bw-components.form-input.combo .input .top .show {
    width: 2rem;
}

.bw-components.form-input.combo .options {
    width: 100%;
    overflow: auto;
    max-height: 10rem;
    border-top: 1px solid #000;
}

.bw-components.form-input.combo .options div {
    padding: 0.4rem;
}

.bw-components.form-input.combo .options div:hover,
.bw-components.form-input.combo .options div.active {
    background-color: #7abee5;
}

.bw-components.textarea textarea {
    resize: none;
    width: 100%;
    height: 8rem;
}

.bw-components.form-input.combo .selected {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.bw-components.form-input.combo .selected .option {
    border: 1px solid #000;
    border-radius: 1rem;
    padding: 0.4rem 0.8rem;
    margin: 0 0.5rem 0.3rem 0;
    box-sizing: border-box;
    background-color: white;
    display: flex;
}

.bw-components.form-input.combo .selected .option button {
    margin-left: 0.5rem;
    border: none;
    background: transparent;
}

.bw-components.form-input.combo .selected .option span {
    display: flex;
}

.bw-components.icon {
    width: unset;
    height: unset;
}

.bw-components.icon.small {
    width: 1rem;
}

.bw-components.icon.medium {
    width: 1.5rem;
}

.bw-components.icon.large {
    width: 2rem;
}

.autocomplete {
    position: relative;
    display: flex;
}

.autocomplete input {
    flex: 1;
}

.autocomplete .autocomplete-items div {
    padding: 0.5rem;
    cursor: pointer;
    background-color: white;
    border-bottom: 1px solid darkgray;
}

.autocomplete .autocomplete-items div:hover {
    background-color: lightgray;
}

.autocomplete .autocomplete-items div.active {
    background-color: var(--main-bg-color);
    color: white;
}

.autocomplete .autocomplete-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 1px solid darkgray;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}

.bw-components.collapse div.openClose {
    fill: #085382;
    font-size: 3rem;
    transition-property: transform;
    transition-duration: 0.25s;
    display: inline-flex;
}
.bw-components.collapse div.openClose:hover {
    fill: #05426a;
    cursor: pointer;
}

.bw-components.collapse div.openClose.open {
    transform: rotate(180deg);
}




/*# sourceMappingURL=/bw-components.css.map */