:root {
    --main-bg-color: #085382;
    --main-bg-color-light: #3879A3;
    --main-bg-color-dark: #05426a;
    --basic-color-yellow-dark: rgb(241, 177, 5);
    --basic-color-yellow-light: rgb(248, 238, 212);
    --basic-color-dark-red: rgb(166, 3, 3);
    --basic-color-pink: rgb(253, 242, 242);
    --basic-color-white: rgb(255, 255, 255);
    --color-action-on-background: var(--main-bg-color);
    --color-background: var(--basic-color-white);

    --color-surface: var(--main-bg-color-dark);
    --color-on-surface: rgb(213, 213, 213);

    --color-primary: var(--main-bg-color);
    --color-primary_rgb: 0, 99, 157;
    --color-on-primary: var(--basic-color-white);
    --color-on-primary_rgb: 255, 255, 255;

    --color-secondary: rgb(0, 106, 106);
    --color-secondary_rgb: 0, 106, 106;
    --color-on-secondary: var(--basic-color-white);
    --color-on-secondary_rgb: 255, 255, 255;
    --color-secondary-background: rgb(241, 255, 254);

    --color-error: var(--basic-color-dark-red);
    --color-on-error: var(--basic-color-pink);
    --color-warning: var(--basic-color-yellow-dark);
    --color-on-warning: var(--basic-color-yellow-light);

    --color-disabled: rgba(31, 31, 31, 0.12);
    --color-on-disabled: rgba(31, 31, 31, 0.38);

    --opacity-hover: 0.08;
    --opacity-active: 0.12;

    --button-shadow-hover: 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15);
    --button-shadow-active: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3);
}

header, footer {
    background: rgb(8, 83, 130);
}

.input-field {
    display: flex;
    align-items: center;
    margin-top: 0.25em;
}

form fieldset > div {
    margin: 0.7em 0;
}

.input-field input {
    flex: 1;
    box-sizing: border-box;
}

header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-weight:bold;
    padding-inline: 16px;
    height: 50px;
}
header .logout{
    text-decoration: none;
    padding: 6px 8px;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
}

.main .submit-and-cancel{
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
}

div.submit-and-cancel, .div.submit {
    margin-top: 2em;
}

div.submit-and-cancel > button {
    margin-right: 1em;
    font-size: initial;
}

header div {
    color: white;
}

header .userName a {
    color: white;
    margin: 0 1em;
}

header .title {
    font-size: x-large;
    grid-column-start: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
header .bw-components.small.logo {
    fill: #fff;
    width: 32px;
    height: 32px;
    padding-inline: 0.3em;
}

.headerLogo {
    display: flex;
}

header .userName {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

body {
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

main{
    grid-area: main;
    overflow: auto;
}
.confirm-email button{
    margin-top: 10px;
}

main#appMain {
    display: grid;
    grid-template-columns: auto 700px auto;
}

footer {
    grid-area: footer;
}

#appRoot {

    grid-template-areas: "header" "main" "nav" "footer";

    grid-template-columns: 1fr;
    grid-template-rows: 2em /* Header */ 1fr /* Content */ minmax(75px, auto) /* Nav */ 2em; /* Footer */
}

#app {
    height: 100vh;
    display: grid;
    grid-template-areas: "header header" "main main" "footer footer";
    grid-template-rows: 3.25em 1fr 2em;
    grid-template-columns: 1fr;
}

.main {
    margin: 25px auto 100px auto;
    padding: 0 20px;
    grid-column-start: 2;
    text-align: center;
    max-width: 400px;
}

.main h1{
    margin-bottom: 50px;
}

.main label{
    text-align: left;
}

.main input{
    border-color: var(--color-action-on-background);
    outline-color: var(--color-action-on-background);
    font-size: 20px;
    height: 3rem;
}

.main h2{
    color: var(--color-action-on-background);
}

@media (max-width: 800px) {
    header {
        grid-template-columns: 1fr 1fr;
    }

    header .title {
        grid-column-start: 1;
    }
}

@media (max-width: 720px) {
    main#appMain {
        grid-template-columns: 1fr;
    }

    .main {
        grid-column-start: unset;
    }

    #organizationSelect {
        grid-column-start: 1;
    }
}

@media (max-width: 540px) {
    #app {
        grid-template-rows: 3em 1fr 2em;
    }
}

#actionBar {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 1em);
    padding: 1em 0.5em 1em 0.5em;
}

#actionBar .menu a {
    margin: 0 0.5em;
}
#actionBar .menu {
    margin-right: 1em;
}

.actionMain {
    padding: 2em;
}

.lightbox-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.lightbox-content {
    position: absolute;
    top: 5em;
    left: 5em;
    right: 5em;
    padding: 1em;
    z-index: 10;
}

.lightbox-content {
    background-color: white
}

.lightbox-backdrop {
    background-color: rgba(8, 83, 130, 0.8);
}

.main .bw-components.small.logo {
    fill: var(--main-bg-color);
    width: 200px;
    height: 200px;
    padding-block: 0.3em;
}

.bw-header {
    display:flex;
    flex-direction: column;
    color: var(--main-bg-color);
    margin-bottom: 24px;
    gap: 10px;
}

.bw-header div:nth-child(1){
    font-size: 20px;
    font-weight: bold;
}

.notification {
    border: 1px solid green;
    background-color: lightgreen;
    padding: 1em;
    width: auto;
}
.error-requestError {
    border: 2px solid var(--color-warning);
    border-radius: 5px;
    background-color: var(--color-on-warning);
    padding: 1em;
    width: auto;
    margin-bottom:10px;
}

.error-requestError .icon {
    fill: var(--color-warning);
}

.error-serverError {
    border: 2px solid var(--color-error);
    border-radius: 5px;
    background-color: var(--color-on-error);
    padding: 1em;
    width: auto;
    margin-bottom: 10px;
}


.btn {
    background-color: #e6e6e6;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    border: none;
}

.btn:hover:enabled {
    background-color: #ccc;
}

.btn:disabled {
    background-color: #F5F5F5;
    cursor: default;
}

.btn-success {
    padding: 1em;
    background-color: #008e4a;
    color: white;
}

.btn-success:hover:enabled {
    background-color: #00743c;
}

.btn-success:disabled {
    background-color: #99D1B6;
}

.btn-primary {
    background-color: var(--main-bg-color);
    color: white;
}

.btn-primary:hover:enabled {
    background-color: var(--main-bg-color-dark);
}

.btn-primary:disabled {
    background-color: #9CBACD;
}
