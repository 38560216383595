:root {
  --main-bg-color: #085382;
  --main-bg-color-light: #3879a3;
  --main-bg-color-dark: #05426a;
  --basic-color-yellow-dark: #f1b105;
  --basic-color-yellow-light: #f8eed4;
  --basic-color-dark-red: #a60303;
  --basic-color-pink: #fdf2f2;
  --basic-color-white: #fff;
  --color-action-on-background: var(--main-bg-color);
  --color-background: var(--basic-color-white);
  --color-surface: var(--main-bg-color-dark);
  --color-on-surface: #d5d5d5;
  --color-primary: var(--main-bg-color);
  --color-primary_rgb: 0, 99, 157;
  --color-on-primary: var(--basic-color-white);
  --color-on-primary_rgb: 255, 255, 255;
  --color-secondary: #006a6a;
  --color-secondary_rgb: 0, 106, 106;
  --color-on-secondary: var(--basic-color-white);
  --color-on-secondary_rgb: 255, 255, 255;
  --color-secondary-background: #f1fffe;
  --color-error: var(--basic-color-dark-red);
  --color-on-error: var(--basic-color-pink);
  --color-warning: var(--basic-color-yellow-dark);
  --color-on-warning: var(--basic-color-yellow-light);
  --color-disabled: #1f1f1f1f;
  --color-on-disabled: #1f1f1f61;
  --opacity-hover: .08;
  --opacity-active: .12;
  --button-shadow-hover: 0 1px 2px #0000004d, 0 1px 3px 1px #00000026;
  --button-shadow-active: 0 1px 3px 1px #00000026, 0 1px 2px #0000004d;
}

header, footer {
  background: #085382;
}

form fieldset > div {
  margin: .7em 0;
}

.input-field input {
  box-sizing: border-box;
  flex: 1;
}

header {
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-inline: 16px;
  font-weight: bold;
  display: flex;
}

header .logout {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 6px 8px;
  text-decoration: none;
}

.main .submit-and-cancel {
  text-align: left;
  grid-column-gap: 1em;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

div.submit-and-cancel, .div.submit {
  margin-top: 2em;
}

div.submit-and-cancel > button {
  font-size: initial;
  margin-right: 1em;
}

header div {
  color: #fff;
}

header .userName a {
  color: #fff;
  margin: 0 1em;
}

header .title {
  grid-column-start: 2;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  display: flex;
}

header .bw-components.small.logo {
  fill: #fff;
  width: 32px;
  height: 32px;
  padding-inline: .3em;
}

.headerLogo {
  display: flex;
}

header .userName {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

body {
  margin: 0;
  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}

main {
  grid-area: main;
  overflow: auto;
}

.confirm-email button {
  margin-top: 10px;
}

main#appMain {
  grid-template-columns: auto 700px auto;
  display: grid;
}

footer {
  grid-area: footer;
}

#appRoot {
  grid-template: "header" 2em
                 "main" 1fr
                 "nav" minmax(75px, auto)
                 "footer" 2em
                 / 1fr;
}

#app {
  grid-template: "header header" 3.25em
                 "main main" 1fr
                 "footer footer" 2em
                 / 1fr;
  height: 100vh;
  display: grid;
}

.main {
  text-align: center;
  grid-column-start: 2;
  max-width: 400px;
  margin: 25px auto 100px;
  padding: 0 20px;
}

.main h1 {
  margin-bottom: 50px;
}

.main label {
  text-align: left;
}

.main input {
  border-color: var(--color-action-on-background);
  outline-color: var(--color-action-on-background);
  height: 3rem;
  font-size: 20px;
}

.main h2 {
  color: var(--color-action-on-background);
}

@media (width <= 800px) {
  header {
    grid-template-columns: 1fr 1fr;
  }

  header .title {
    grid-column-start: 1;
  }
}

@media (width <= 720px) {
  main#appMain {
    grid-template-columns: 1fr;
  }

  .main {
    grid-column-start: unset;
  }

  #organizationSelect {
    grid-column-start: 1;
  }
}

@media (width <= 540px) {
  #app {
    grid-template-rows: 3em 1fr 2em;
  }
}

#actionBar {
  justify-content: space-between;
  width: calc(100% - 1em);
  padding: 1em .5em;
  display: flex;
}

#actionBar .menu a {
  margin: 0 .5em;
}

#actionBar .menu {
  margin-right: 1em;
}

.actionMain {
  padding: 2em;
}

.lightbox-backdrop {
  position: absolute;
  inset: 0;
}

.lightbox-content {
  z-index: 10;
  background-color: #fff;
  padding: 1em;
  position: absolute;
  top: 5em;
  left: 5em;
  right: 5em;
}

.lightbox-backdrop {
  background-color: #085382cc;
}

.main .bw-components.small.logo {
  fill: var(--main-bg-color);
  width: 200px;
  height: 200px;
  padding-block: .3em;
}

.bw-header {
  color: var(--main-bg-color);
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  display: flex;
}

.bw-header div:first-child {
  font-size: 20px;
  font-weight: bold;
}

.notification {
  background-color: #90ee90;
  border: 1px solid green;
  width: auto;
  padding: 1em;
}

.error-requestError {
  border: 2px solid var(--color-warning);
  background-color: var(--color-on-warning);
  border-radius: 5px;
  width: auto;
  margin-bottom: 10px;
  padding: 1em;
}

.error-requestError .icon {
  fill: var(--color-warning);
}

.error-serverError {
  border: 2px solid var(--color-error);
  background-color: var(--color-on-error);
  border-radius: 5px;
  width: auto;
  margin-bottom: 10px;
  padding: 1em;
}

.btn {
  cursor: pointer;
  background-color: #e6e6e6;
  border: none;
  border-radius: .25em;
  padding: .5em 1em;
}

.btn-success {
  color: #fff;
  background-color: #008e4a;
  padding: 1em;
}

.btn-success:hover:enabled {
  background-color: #00743c;
}

.btn-success:disabled {
  background-color: #99d1b6;
}

:root {
  --main-bg-color: #085382;
  --main-bg-color-light: #3879a3;
  --main-bg-color-dark: #05426a;
}

.bw-components.lightbox {
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.bw-components.lightbox .lightbox-backdrop {
  background-color: #081932d9;
  display: flex;
  position: absolute;
  inset: 0;
}

.bw-components.lightbox .lightbox-content {
  box-sizing: border-box;
  z-index: 10;
  background-color: #fff;
  border-radius: 3px;
  width: calc(100% - 40px);
  max-width: 550px;
  max-height: 85%;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.bw-components.lightbox .lightbox-content .lightbox-scroll-container {
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

.bw-components.lightbox .lightbox-content.certificates {
  max-width: none;
}

.bw-components.lightbox .lightbox-content .lightbox-scroll-container.lightbox-content-title {
  padding-top: 0;
}

.bw-components.lightbox .lightbox-title {
  border-bottom: 1px solid #e5e5e5;
  justify-content: space-between;
  padding: 20px 0 10px;
  display: flex;
}

.bw-components.lightbox .lightbox-title h4 {
  margin: 0;
}

.bw-components.lightbox .lightbox-title button {
  background: none;
  border: none;
  align-self: center;
  padding: 0;
}

.bw-components.lightbox .lightbox-title svg {
  opacity: .2;
  cursor: pointer;
  width: .8rem;
}

.bw-components.lightbox .lightbox-title svg:hover {
  opacity: .5;
}

.lds-roller-centered-wrapper {
  justify-content: center;
  margin: 4em;
  display: flex;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: var(--main-bg-color);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.progress-wrapper {
  position: relative;
}

.progress {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

div.lightbox-content .bw-components table {
  table-layout: auto;
}

.bw-components table {
  border-collapse: collapse;
  width: 100%;
}

.bw-components table td, .bw-components table th {
  text-overflow: ellipsis;
  overflow: hidden;
}

.bw-components table a {
  text-decoration: none;
}

.bw-components th > span.sortable, .bw-components th > span.filterable {
  cursor: pointer;
}

.bw-components th > span.sortable:hover, .bw-components th > span.filterable:hover {
  color: var(--main-bg-color-light);
}

.bw-components th {
  text-align: left;
}

.bw-components tr:nth-child(2n) {
  background-color: #eff4ff;
}

.bw-components tbody tr:hover {
  background-color: #dae5fe;
}

.bw-components tr.clickable {
  cursor: pointer;
}

.bw-components td.centered, .bw-components th.centered {
  text-align: center;
}

.bw-components td.strong {
  font-weight: bold;
}

.bw-components .table-header {
  display: flex;
}

.bw-components table .column-filter {
  background: #fff;
  padding: .5em;
  position: absolute;
}

.bw-components .table-toolbar {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
  display: flex;
}

.bw-components span.searchbox input {
  border: none;
  padding-left: .4rem;
}

.bw-components span.searchbox input:focus {
  outline: none;
}

.bw-components span.searchbox {
  border: 1px solid #e8e8e8;
  border-radius: .25em;
  outline: none;
  margin-left: 1rem;
  padding: .4rem;
}

.bw-components span.searchbox:focus-within {
  box-shadow: 0 0 5px #ddd;
}

.bw-components span.searchbox {
  align-items: center;
  display: inline-flex;
}

@media (width <= 1280px) {
  .bw-components table {
    display: block;
  }

  .bw-components thead {
    display: none;
  }

  .bw-components tbody {
    width: calc(100% - 6px);
    display: table;
  }

  .bw-components tr {
    border: 3px solid #888;
    display: table-row-group;
  }

  .bw-components td {
    display: table-row;
  }

  .bw-components td:before, .bw-components td > span {
    border: 1px solid #e8e8e8;
    padding: 7px 13px;
    display: table-cell;
  }

  .bw-components td:before {
    content: attr(data-label);
    font-weight: 600;
  }

  .bw-components .table-toolbar {
    flex-direction: column;
  }

  .bw-components td.centered, .bw-components th.centered {
    text-align: left;
  }

  .bw-components .table-search {
    flex-direction: column;
    display: flex;
  }

  .bw-components .table-toolbar .table-search > * {
    text-align: center;
    margin: 0;
  }
}

@media (width >= 856px) {
  .bw-components th {
    white-space: nowrap;
    font-weight: 600;
  }

  .bw-components th, .bw-components td {
    border: 1px solid #e8e8e8;
    padding: 7px 13px;
  }
}

.main-section-header {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.main-section-header h1 {
  margin-right: 1em;
}

div.main-section-header > div {
  align-items: center;
  display: inline-flex;
}

@media (width <= 1280px) {
  main.nav-page > div > div.main-section-header {
    flex-direction: column;
  }
}

.bw-components.files {
  display: inline-block;
}

.bw-components.files .file {
  color: #000;
  margin: .25rem .5rem;
  text-decoration: none;
  display: inline-flex;
}

.bw-components.files .file .bw-components.icon {
  margin: 0 .25rem 0 0;
}

.bw-components.files .file .image .thumbnail.small {
  max-width: 1rem;
  max-height: 1rem;
  margin: 0 3px 0 0;
}

.bw-components.files .file .image .preview {
  background-color: #fff;
  display: none;
  position: absolute;
}

.bw-components.files .file .image .preview img {
  max-width: 200px;
  max-height: 200px;
}

.bw-components.files .file:hover .image .preview {
  display: block;
}

input, select, textarea {
  border: 1px solid #000;
  border-radius: .25rem;
  padding: .35rem .5rem;
  font-family: Arial, sans-serif;
  font-size: 1rem;
}

button {
  font-size: initial;
}

.input-field {
  align-items: center;
  margin-top: .5em;
  display: flex;
}

.input-field input, .input-field select, .input-field div {
  box-sizing: border-box;
  flex: 1;
}

.bw-components.form-input input[type="email"].invalidEmail {
  background-color: pink;
  border-color: red;
}

.bw-components.form-input input[type="email"].validEmail {
  background-color: #90ee90;
}

.bw-components.form-input input[type="email"].invalidEmail:focus-within {
  background-color: pink;
}

.bw-components.form-input .label-help {
  display: flex;
}

.bw-components.form-input .label-help svg {
  padding-left: .5rem;
}

.bw-components.submit-and-cancel {
  margin-bottom: 1.5rem;
}

.bw-components.form-input.input label {
  flex-direction: column;
  display: flex;
}

.bw-components.submit-and-cancel button {
  margin-top: 1.5rem;
  margin-right: 1rem;
}

.bw-components.form-input.file button {
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 .5rem 0 0;
  padding: 0;
}

.bw-components.form-input.file input[type="file"].hide, .bw-components.form-input.checkbox .hidden {
  display: none;
}

.bw-components.form-input.checkbox label {
  align-items: center;
  display: flex;
}

.bw-components.form-input.checkbox button {
  background: none;
  border: 0;
  padding: 0;
}

.bw-components.form-input.checkbox .checkbox.disabled {
  color: #dbdbdb;
}

.bw-components.form-input.checkboxgroup .bw-components.form-input.checkbox {
  margin-top: .5rem;
}

.btn, .btn:focus {
  cursor: pointer;
  background-color: #e6e6e6;
  border: none;
  border-radius: .25em;
  padding: .5em 1em;
}

.btn:hover:enabled {
  background-color: #ccc;
}

.btn:disabled {
  cursor: default;
  background-color: #f5f5f5;
}

.btn-primary {
  background-color: var(--main-bg-color);
  color: #fff;
}

.btn-primary:hover:enabled {
  background-color: var(--main-bg-color-dark);
}

.btn-primary:disabled {
  background-color: #9cbacd;
}

.bw-components.form-input.combo .input-field {
  height: 2.1rem;
  display: block;
  position: relative;
}

.bw-components.form-input.combo .input {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.bw-components.form-input.combo .input.show {
  z-index: 100;
}

.bw-components.form-input.combo .input .top {
  width: 100%;
  display: flex;
}

.bw-components.form-input.combo .input .top button {
  cursor: pointer;
  background: none;
  border: none;
  border-left: 1px solid #000;
  padding: 0;
  display: flex;
}

.bw-components.form-input.combo .input .top input {
  border: 0;
  border-radius: 0;
  outline: none;
  height: 2rem;
}

.bw-components.form-input.combo .input .top .show {
  width: 2rem;
}

.bw-components.form-input.combo .options {
  border-top: 1px solid #000;
  width: 100%;
  max-height: 10rem;
  overflow: auto;
}

.bw-components.form-input.combo .options div {
  padding: .4rem;
}

.bw-components.form-input.combo .options div:hover, .bw-components.form-input.combo .options div.active {
  background-color: #7abee5;
}

.bw-components.textarea textarea {
  resize: none;
  width: 100%;
  height: 8rem;
}

.bw-components.form-input.combo .selected {
  flex-wrap: wrap;
  max-width: 100%;
  display: flex;
}

.bw-components.form-input.combo .selected .option {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 1rem;
  margin: 0 .5rem .3rem 0;
  padding: .4rem .8rem;
  display: flex;
}

.bw-components.form-input.combo .selected .option button {
  background: none;
  border: none;
  margin-left: .5rem;
}

.bw-components.form-input.combo .selected .option span {
  display: flex;
}

.bw-components.icon {
  width: unset;
  height: unset;
}

.bw-components.icon.small {
  width: 1rem;
}

.bw-components.icon.medium {
  width: 1.5rem;
}

.bw-components.icon.large {
  width: 2rem;
}

.autocomplete {
  display: flex;
  position: relative;
}

.autocomplete input {
  flex: 1;
}

.autocomplete .autocomplete-items div {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #a9a9a9;
  padding: .5rem;
}

.autocomplete .autocomplete-items div:hover {
  background-color: #d3d3d3;
}

.autocomplete .autocomplete-items div.active {
  background-color: var(--main-bg-color);
  color: #fff;
}

.autocomplete .autocomplete-items {
  z-index: 99;
  border: none;
  border-left: 1px solid #a9a9a9;
  border-right: 1px solid #a9a9a9;
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.bw-components.collapse div.openClose {
  fill: #085382;
  font-size: 3rem;
  transition-property: transform;
  transition-duration: .25s;
  display: inline-flex;
}

.bw-components.collapse div.openClose:hover {
  fill: #05426a;
  cursor: pointer;
}

.bw-components.collapse div.openClose.open {
  transform: rotate(180deg);
}
/*# sourceMappingURL=error.70ecc518.css.map */
